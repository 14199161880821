import React, { useContext, useEffect, useState } from 'react'
import Link from 'next/link'
import { imgApiUrl, BASE_URL } from '../../../lib/constants'
import { MainContext } from '../../../contexts/MainContext'
import Image from 'next/dist/client/image'
import { MessageContext } from '../../../contexts/MessageContext'
import styles from './Product.module.css'

const Product = (props: any) => {
	const { redirectTo, addToCart, totalCartItems, setTotalCartItems, countTotalCartSum, getProductQty } = useContext(MainContext)
	const { setAlert } = useContext(MessageContext)

	const [qty, setQty] = useState(0)
	// const [proQty, setProQty] = useState(getProductQty(props.product.id))
	const gsapFunc = async () => {
		const gsap = (await import('gsap')).default
		gsap?.timeline().fromTo(
			'.alert-show',
			{
				xPercent: -50,
				autoAlpha: 0
			},
			{
				xPercent: 0,
				ease: 'back(2)',
				autoAlpha: 1,
				onComplete: () => {
					gsap?.to('.alert-show', {
						xPercent: -50,
						autoAlpha: 0,
						duration: 0.3,
						delay: 2
					})
				}
			},
			'<90%'
		)
	}
	const addCart = async (product: any, qtyNum: any = 0, type = 'add') => {
		let quantity = qty
		let alertType = 'info'
		let alertMsg = ''
		if (type == 'add') {
			quantity = quantity + qtyNum
			alertMsg = 'Item added in cart'
		}

		if (type == 'sub') {
			quantity = quantity - qtyNum
			alertType = 'danger'
			alertMsg = 'Item removed from cart'
		}
		if (quantity >= 0) {
			await addToCart(product, qtyNum, type)
			await countTotalCartSum()
			let currentProductQty = await getProductQty(product?.id)
			await setQty(currentProductQty)
			await setAlert({
				type: alertType,
				display: true,
				message: alertMsg
			})
			await gsapFunc()
		}
	}

	// Extracting prices from props
	const catalogPrice = props?.product?.price_catalog
	const discountedPrice = props?.product?.price_discounted

	// Calculating the discount percentage
	const discountPercentage = ((catalogPrice - discountedPrice) / catalogPrice) * 100

	const apiDate = props?.product?.price_discounted_end

	const discountedEndDate = apiDate ? new Date(apiDate) : null
	const Dis_percent = ((props?.product?.price_catalog - props?.product?.price_discounted) / props?.product?.price_catalog) * 100
	const price =
		props?.product?.price_discounted != null && props?.product?.price_discounted != 0 && (discountedEndDate === null || discountedEndDate >= new Date()) ? (
			<>
				<span className='old-price mr-2 text-[15px] text-[#999999] line-through'>${props?.product?.price_catalog?.toFixed(2)}</span>
				<span className='new-price mr-2 text-[17px] font-bold'>${props?.product?.price_discounted?.toFixed(2)}</span>

				{/* <span className='old-price text-xs text-red-400 font-bold'>{Dis_percent?.toFixed(2)}% Off</span> */}
			</>
		) : props?.product?.price < props?.product?.price_catalog ? (
			<span className='new-price mr-2 font-bold text-[17px]'>${props?.product?.price?.toFixed(2)}</span>
		) : (
			// <span className='new-price mr-2 font-bold'>${props.product?.price_catalog?.toFixed(2)}</span>
			<span className='new-price mr-2 font-bold text-[17px]'>{props?.product?.price_catalog ? `$${props?.product?.price_catalog?.toFixed(2)}` : 'Multiple SKUs, Click For Details'}</span>
		)

	const ProductClickFunc = (e) => {
		e.preventDefault()

		let productContainer: any = document.querySelector('.featured-product'),
			productImgWrapper: any = document.querySelector('.featured-products-img-wrapper'),
			productImgWrapperImg: any = document.querySelector('.featured-products-img-wrapper img'),
			AddIcon: any = document.querySelector('svg.add_to_Cart'),
			AddIconPath: any = document.querySelector('svg.add_to_Cart path')

		if (e.target.classList[1] == 'featured-product') {
			redirectTo(`/${props?.product?.slug}`)
		}
		const targetClassList = e.target.classList

		if (targetClassList.contains('featured-product')) {
			redirectTo(`/${props?.product?.slug}`)
		}

		// when clicking on plus sign
		if (e.target.classList.value === AddIcon.classList.value || e.target.getAttribute('d') === AddIconPath.getAttribute('d')) {
			if (e.target.classList.value === AddIcon.classList.value) {
				e.target.nextSibling.classList.toggle('hidden')
				e.target.nextSibling.classList.toggle('flex')
			} else if (e.target.getAttribute('d') === AddIconPath.getAttribute('d')) {
				e.target.parentElement.nextSibling.classList.toggle('hidden')
				e.target.parentElement.nextSibling.classList.toggle('flex')
			}
		}
	}

	useEffect(() => {
		const getCurrentQty = async () => {
			let currentQty = await getProductQty(props?.product?.id)
			await setQty(currentQty)
		}
		getCurrentQty()
	}, [props?.product])

	return (
		<Link href={`/${props?.product?.slug}`}>
			<a aria-label={props?.product?.name}>
				<div
					className={`${props?.className} ${props?.type === 'offer' ? styles.offer_product : undefined} ${props?.type === 'offer-horizontal' ? styles.horzontal_product : undefined} featured-product relative ${
						props?.type !== 'offer' && props?.type !== 'offer-horizontal' ? 'shadow-lg' : undefined
					}  ${props?.type !== 'offer' && props?.type !== 'offer-horizontal' ? 'rounded-lg' : undefined}  bg-white flex flex-col p-4  cursor-pointer w-full h-full`}
					onClick={(e) => ProductClickFunc(e)}
				>
					<div className='featured-products-img-wrapper flex justify-center featured-product-img productImage'>
						{props?.product?.price_discounted != '' && props?.product?.price_discounted != 0 && props?.product?.price_discounted != null ? (
							<>
								<div className='sale-notify absolute top-0 left-0 uppercase text-xs dark-blue-bg w-max text-white  px-2 py-1 tracking-widest z-10'>{Math.trunc(discountPercentage)}% Off</div>
							</>
						) : (
							''
						)}

						<Image
							layout='intrinsic'
							width={200}
							height={200}
							src={props?.product?.image != null ? `${imgApiUrl?.products?.medium}/${props?.product?.image}` : `${BASE_URL}/img/image.jpg`}
							alt={props?.product?.name}
							blurDataURL={`${imgApiUrl?.products?.medium}$/{props?.product?.image}`}
							placeholder='blur'
							lazyBoundary='400px'
						/>
					</div>
					{props?.offer !== 'offer' && (
						<style jsx>{`
							.productImage {
								padding-left: 25%;
							}
						`}</style>
					)}

					{props?.type !== 'offer' && props?.type !== 'offer-horizontal' && (
						<div className='add-icon-wrapper relative z-10 self-end'>
							<svg xmlns='http://www.w3.org/2000/svg' className='h-12 w-12 relative self-end cursor-pointer z-10 add_to_Cart' fill='#52A0F2' viewBox='0 0 24 24' stroke='#fff'>
								<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1} d='M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z' className='addBtn' />
							</svg>
							<div className='add-minus-qty-wrapper bg-white shadow-lg rounded-full text-black border border-solid border-gray-300 absolute top-2 right-11 hidden'>
								<div id='quanity-minus' className='quantity quanity-minus py-2 px-3.5 text-normal hover:bg-gray-100 rounded-l-full cursor-pointer' onClick={(e) => addCart(props?.product, 1, 'sub')}>
									-
								</div>
								<input name='product-quantity' id={`product-quantity${props?.product?.id}`} type='text' min={1} size={4} className='hover:bg-gray-100 text-center bg-white inline-block' value={qty} onChange={(e) => addCart(props?.product, 1, 'change')} />
								<div id='quanity-add' className='quantity quanity-add py-2 px-3 text-normal hover:bg-gray-100 rounded-r-full cursor-pointer' onClick={(e) => addCart(props?.product, 1, 'add')}>
									+
								</div>
							</div>
						</div>
					)}
					<div className={`feature-product-detail-wrapper ${styles.price_wrapper}`}>
						<div className='product-description font-bold leading-snug mt-4  '>
							{props?.product?.name?.substring(0, 40)} {props?.product?.name?.length > 40 ? '...' : ''}
						</div>
						<div className={`${styles.price} product-price-text price flex mt-2 items-center`}>{price}</div>
					</div>
				</div>
			</a>
		</Link>
	)
}

export default Product
